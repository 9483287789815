/*
Copyright 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import {Options as BaseOptions} from "../../../ViewModel";
import {DismissibleVerificationViewModel} from "./DismissibleVerificationViewModel";
import {CancelReason} from "../../../../matrix/verification/SAS/channel/types";
import type {Session} from "../../../../matrix/Session.js";
import type {IChannel} from "../../../../matrix/verification/SAS/channel/IChannel";
import type {SASVerification} from "../../../../matrix/verification/SAS/SASVerification";

type Options = BaseOptions & {
    cancellation: IChannel["cancellation"];
    session: Session;
    sas: SASVerification; 
};

export class VerificationCancelledViewModel extends DismissibleVerificationViewModel<Options> {
    get cancelCode(): CancelReason {
        return this.options.cancellation!.code;
    }

    get isCancelledByUs(): boolean {
        return this.options.cancellation!.cancelledByUs;
    }

    get kind(): string {
        return "verification-cancelled";
    }

    get title(): string {
        if (this.isCancelledByUs) {
            return `Du hast die Verifizierung abgebrochen!`;
        }
        if (this.getOption("sas").isCrossSigningAnotherUser) {
            return `Der andere Benutzer hat die Verifizierung abgebrochen!`;
        }
        else {
            return `Das andere Gerät hat die Verifizierung abgebrochen!`;
        }
    }

    get description(): string {
        const descriptionsWhenWeCancelledForDeviceVerification = {
            [CancelReason.InvalidMessage]: "Dein anderes Gerät hat eine ungültige Nachricht gesendet.",
            [CancelReason.KeyMismatch]: "Der Schlüssel konnte nicht verifiziert werden.",
            [CancelReason.TimedOut]: "Der Verifizierungsprozess ist abgelaufen.",
            [CancelReason.UnexpectedMessage]: "Dein anderes Gerät hat eine unerwartete Nachricht gesendet.",
            [CancelReason.UnknownMethod]: "Dein anderes Gerät verwendet eine unbekannte Methode zur Verifizierung.",
            [CancelReason.UnknownTransaction]: "Dein anderes Gerät hat eine Nachricht mit einer unbekannten Transaktions-ID gesendet.",
            [CancelReason.UserMismatch]: "Der erwartete Benutzer stimmte nicht mit dem verifizierten Benutzer überein.",
            [CancelReason.MismatchedCommitment]: "Die Hash-Verpflichtung stimmt nicht überein.",
            [CancelReason.MismatchedSAS]: "Das Emoji/Dezimal stimmt nicht überein.",
        }
        const descriptionsWhenTheyCancelledForDeviceVerification = {
            [CancelReason.UserCancelled]: "Dein anderes Gerät hat die Verifizierung abgebrochen!",
            [CancelReason.InvalidMessage]: "Ungültige Nachricht an das andere Gerät gesendet.",
            [CancelReason.KeyMismatch]: "Das andere Gerät konnte unsere Schlüssel nicht verifizieren",
            [CancelReason.TimedOut]: "Der Verifizierungsprozess ist abgelaufen.",
            [CancelReason.UnexpectedMessage]: "Unerwartete Nachricht an das andere Gerät gesendet.",
            [CancelReason.UnknownMethod]: "Dein anderes Gerät versteht die von dir gewählte Methode nicht",
            [CancelReason.UnknownTransaction]: "Dein anderes Gerät hat unsere Nachricht abgelehnt.",
            [CancelReason.UserMismatch]: "Der erwartete Benutzer stimmte nicht mit dem verifizierten Benutzer überein.",
            [CancelReason.MismatchedCommitment]: "Dein anderes Gerät konnte die Hash-Verpflichtung nicht verifizieren",
            [CancelReason.MismatchedSAS]: "Das Emoji/Dezimal stimmt nicht überein.",
        }
        const descriptionsWhenWeCancelledForCrossSigning = {
            [CancelReason.InvalidMessage]: "Der andere Benutzer hat eine ungültige Nachricht gesendet.",
            [CancelReason.KeyMismatch]: "Der Schlüssel konnte nicht verifiziert werden.",
            [CancelReason.TimedOut]: "Der Verifizierungsprozess ist abgelaufen.",
            [CancelReason.UnexpectedMessage]: "Der andere Benutzer hat eine unerwartete Nachricht gesendet.",
            [CancelReason.UnknownMethod]: "Der andere Benutzer verwendet eine unbekannte Methode zur Verifizierung.",
            [CancelReason.UnknownTransaction]: "Der andere Benutzer hat eine Nachricht mit einer unbekannten Transaktions-ID gesendet.",
            [CancelReason.UserMismatch]: "Der erwartete Benutzer stimmte nicht mit dem verifizierten Benutzer überein.",
            [CancelReason.MismatchedCommitment]: "Die Hash-Verpflichtung stimmt nicht überein.",
            [CancelReason.MismatchedSAS]: "Das Emoji/Dezimal stimmt nicht überein.",
        }
        const descriptionsWhenTheyCancelledForCrossSigning = {
            [CancelReason.UserCancelled]: "Der andere Benutzer hat die Verifizierung abgebrochen!",
            [CancelReason.InvalidMessage]: "Ungültige Nachricht an den anderen Benutzer gesendet.",
            [CancelReason.KeyMismatch]: "Der andere Benutzer konnte unsere Schlüssel nicht verifizieren",
            [CancelReason.TimedOut]: "Der Verifizierungsprozess ist abgelaufen.",
            [CancelReason.UnexpectedMessage]: "Unerwartete Nachricht an den anderen Benutzer gesendet.",
            [CancelReason.UnknownMethod]: "Der andere Benutzer versteht die von dir gewählte Methode nicht",
            [CancelReason.UnknownTransaction]: "Der andere Benutzer hat unsere Nachricht abgelehnt.",
            [CancelReason.UserMismatch]: "Der erwartete Benutzer stimmte nicht mit dem verifizierten Benutzer überein.",
            [CancelReason.MismatchedCommitment]: "Der andere Benutzer konnte die Hash-Verpflichtung nicht verifizieren",
            [CancelReason.MismatchedSAS]: "Das Emoji/Dezimal stimmt nicht überein.",
        }
        
        let map;
        if (this.getOption("sas").isCrossSigningAnotherUser) {
            map = this.isCancelledByUs ? descriptionsWhenWeCancelledForCrossSigning : descriptionsWhenTheyCancelledForCrossSigning;
        } else {
            map = this.isCancelledByUs ? descriptionsWhenWeCancelledForDeviceVerification : descriptionsWhenTheyCancelledForDeviceVerification;
        }
        const description = map[this.cancelCode] ?? ""
        return `${description}`;
        
    }
}


            import {main} from "./main";
            import {Platform} from "./Platform";
            import configURL from "./assets/config.json?url";
            import assetPaths from "./sdk/paths/vite";
            import Keycloak from 'keycloak-js';

            const keycloakConfig = {
                url: "https://auth.kunden-mc.i-motion.de/auth/",
                realm: "kunden-i-motion",
                clientId: "test-ticketdashboard",
            };

            const keycloak = new Keycloak(keycloakConfig);

            keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
                if (!authenticated) {
                    keycloak.login(); // Redirects user to authentication service
                } else {
                    // Attach keycloak object to the window
                    window.keycloakInstance = keycloak;
                    
                    if (import.meta.env.PROD) {
                        assetPaths.serviceWorker = "sw.js";
                    }
                    const platform = new Platform({  
                        container: document.body,
                        assetPaths,
                        configURL, 
                        options: {development: import.meta.env.DEV}
                    });
                    main(platform);
                }
            }).catch(error => {
                console.error("Keycloak initialization error:", error);
            });


        
/* I-Motion customized */

/*
Copyright 2020 Bruno Windels <bruno@windels.cloud>
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

import {TemplateView} from "../general/TemplateView";
import axios from "axios";

export class CreateTicketView extends TemplateView {
    render(t, vm) {
        return t.main({className: "middle"}, 
            t.div({className: "CreateRoomView centered-column"}, [
                t.h2("Ticket erstellen"),
                //t.div({className: "RoomView_error"}, vm => vm.error),
                t.form({ id: "ticketSubmission", className: "CreateRoomView_detailsForm form", onSubmit: evt => this.onSubmit(evt)}, [
                    t.div({className: "vertical-layout"}, [
                        t.div({className: "stretch form-row text"}, [
                            t.label({for: "title"}, `Betreff`),
                            t.input({
                                onInput: evt => vm.setTitle(evt.target.value),
                                type: "text", name: "title", id: "title", maxlength: "100",
                                placeholder: `Geben Sie einen Betreff an...`
                            }),
                        ]),
                    ]),
                    t.div({className: "form-row text"}, [
                        t.label({for: "message"}, `Nachricht`),
                        t.textarea({
                            onInput: evt => vm.setMessage(evt.target.value),
                            type: "text", name: "message", id: "message", maxlength: "2000",
                            placeholder: `Bitte beschreiben Sie das Problem... `
                        }),
                    ]),
                    t.div({className: "button-row"}, [
                        t.button({
                            id: "submit",
                            className: "button-action primary",
                            type: "submit",
                            disabled: vm => !vm.canCreate
                        }, `Ticket erstellen`),
                    ])
                ])
            ])
        );
    }

    onSubmit(evt) {
        evt.preventDefault();
    
        const title = document.getElementById("title").value;
        const description = document.getElementById("message").value;

        document.getElementById("ticketSubmission").reset();

        const requestBody = {
            title,
            description,
        };
    
        const headers = {
            'Authorization': `Bearer ${window.keycloakInstance.token}`
        };
        
        axios.post("/v1/tickets", requestBody, {
            headers: headers
        })
        .then((response) => {

            console.log('Ticket created successfully:', response.data);

            var url = window.location.href;
            var roomRef = `room/${response.data.chatroomId}`;
            var parts = url.split('/');
            var index = parts.indexOf("create-ticket");
            parts[index] = roomRef;
            var newUrl = parts.join('/');
            window.location.href = newUrl;

            this.setState({ error: null });
            })
            .catch((error) => {
    
                console.error('Error creating ticket:', error);
                this.setState({ error });
            }); 
    }
    
}
